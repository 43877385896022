import React, { useState } from 'react'
import { portfolioImages3, portfolioImages4, carousel2 } from './Data'
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs'
import { IconContext } from 'react-icons'
import denverDance from '../../Data/Portfolio/Couples/denver-lake-dance-couple-colorado.jpg'
import denverDanceSmall from '../../Data/Portfolio/Couples/Small/denver-lake-dance-couple-colorado-sm.jpg'
import Footer from '../HomeComponents/Footer'
import { motion } from 'framer-motion'

function CouplesPhotos() {
    const [modalPosition, setModalPosition] = useState(0)
    const [isShowModal, setIsShowModal] = useState(false)

    const setModalIndex = (id) => {
        setModalPosition(id)
        setIsShowModal(!isShowModal)
    }

    const closeModal = () => {
        setIsShowModal(false)
    }

    const increasePosition = () => {
        if(modalPosition == carousel2.length - 1) {
            setModalPosition(0)
        } else {
            setModalPosition(modalPosition + 1)
        }
        
    }

    const decreasePosition = () => {
        if(modalPosition == 0) {
            setModalPosition(9)
        } else {
            setModalPosition(modalPosition - 1)
        }
    }

  return (
    <motion.div initial={{opacity: 0 }} transition={{ duration: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div>
            <section id='main-wedding-photos'>
                <div id='wedding-border'>
                <div id='top-photo-container'>
                    <img 
                    onClick={() => setModalIndex(0)} 
                    src={denverDanceSmall} 
                    srcSet={`${denverDanceSmall} 800w, ${denverDance} 2000w`}
                    sizes="(min-width: 700px) 1000w, 400w"
                    alt="denver colorado couple dancing" 
                    className='wedding-photo-top' 
                    width="1300" 
                    height="867" 
                    />
                </div>
                <div id='wedding-inner-border'>
                <div id='wedding-column-1'>
                            {portfolioImages4.map(photo => (
                                <img 
                                onClick={() => setModalIndex(photo.id)} 
                                alt={photo.alt} 
                                className={photo.imageClass} 
                                width={photo.width} 
                                height={photo.height} 
                                srcSet={`${photo.imageSmall} 800w, ${photo.image} 2000w`}
                                sizes="(min-width: 700px) 1000w, 400w"
                                src={photo.imageSmall} 
                                />
                            ))}
                        </div>
                        <div id='wedding-column-2'>
                            {portfolioImages3.map(photo => (
                                <img 
                                onClick={() => setModalIndex(photo.id)} 
                                src={photo.imageSmall} 
                                alt={photo.alt} 
                                className={photo.imageClass} 
                                width={photo.width} 
                                height={photo.height}
                                srcSet={`${photo.imageSmall} 800w, ${photo.image} 2000w`}
                                sizes="(min-width: 700px) 1000w, 400w"
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <div id="myModal" class={isShowModal ? "modal-show" : "modal-hide"}>
                <span class="close" onClick={() => closeModal()}>&times;</span>
                <IconContext.Provider
                    value={{color: '#afb0b4', className: 'wedding-nav-right'}} >
                    <BsChevronRight onClick={() => increasePosition()} />
                </IconContext.Provider>
                <img class="modal-content" id="img01" src={carousel2[modalPosition].image}/>
                <IconContext.Provider
                    value={{color: '#afb0b4', className: 'wedding-nav-left'}} >
                    <BsChevronLeft onClick={() => decreasePosition()} />
                </IconContext.Provider>
                <div id="caption"></div>
            </div>
            <Footer />
        </div>
    </motion.div>
  )
}

export default CouplesPhotos