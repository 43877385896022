import React from 'react'
import AboutContainer from './AboutContainer'
import { motion } from 'framer-motion'
import {Helmet} from "react-helmet";

function About() {
  
  return (
    <motion.div initial={{opacity: 0 }} transition={{ duration: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div>
      <Helmet>
        <meta property='og:title' content='About - Anyssa Sands Photography' />
        <meta property="og:url" content="https://anyssasands.com/about" />
      </Helmet>
        <AboutContainer />
      </div>
    </motion.div>
  )
}

export default About