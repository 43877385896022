import React, { useRef, useLayoutEffect, useState } from 'react'
import Footer from '../HomeComponents/Footer'
import anyssaFrame from '../../Data/About/Anyssa frame.jpg'
import hubs from '../../Data/About/Wedding Pic.jpg'
import hiking from '../../Data/About/Hiking-1.jpg'
import dogs from '../../Data/About/dogs-1.jpg'
import flowers from '../../Data/About/Scrolling-1 (2).jpg'
import solitude from '../../Data/About/solitude-1 (1).jpg'
import songWriting from '../../Data/About/songwriting.JPG'
import anyssaCamera from '../../Data/About/Anyssa Camera.jpg'
import { AiOutlineDown } from 'react-icons/ai'
import { AiOutlineUp } from 'react-icons/ai'
import { IconContext } from 'react-icons'
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom"


function AboutContainer() {
    const [height, setHeight] = useState(0);
    const ref = useRef(null);
    const [desktopPhoto, setDesktopPhoto] = useState("camera-photo-show")
    const [mobilePhoto, setMobilePhoto] = useState("camera-photo-hide")
    const [arraySelect, setArraySelect] = useState(1)
    const location = useLocation()
    const interestsPhotos1 = [
        {
            id: 1,
            image: hubs,
            alt: "wedding",
            subtext: "my hubs"
        },
        {
            id: 2,
            image: hiking,
            alt: "mountains",
            subtext: "hiking"
        },
        {
            id: 3,
            image: dogs,
            alt: "two dogs",
            subtext: "emerson & nora"
        }
        
    ]
    const interestsPhotos2 = [
        {
            id: 1,
            image: flowers,
            alt: "flowers",
            subtext: "flowers"
        },
        {
            id: 2,
            image: solitude,
            alt: "mountain scape",
            subtext: "solitude"
        },
        {
            id: 3,
            image: songWriting,
            alt: "notebook with lyrics",
            subtext: "songwriting"
        }
    ]

    useLayoutEffect(() => {
        window.addEventListener('resize', function() {
          if (ref.current) {
            setHeight((ref.current.clientWidth * 1.35));
          }
        });
        if (ref.current) {
          setHeight((ref.current.clientWidth * 1.35));
        }
      }, [ref]);
    

    useLayoutEffect(() => {
        window.addEventListener('resize', function() {
            if(this.window.innerWidth > 810) {
                setDesktopPhoto("camera-photo-show")
                setMobilePhoto("camera-photo-hide")
            } else {
                setDesktopPhoto("camera-photo-hide")
                setMobilePhoto("camera-photo-show")
            }
          });

          if(window.innerWidth > 810) {
            setDesktopPhoto("camera-photo-show")
            setMobilePhoto("camera-photo-hide")
        } else {
            setDesktopPhoto("camera-photo-hide")
            setMobilePhoto("camera-photo-show")
        }
          
    }, [location])

    window.addEventListener('load', function() {
        if(this.window.innerWidth > 810) {
            setDesktopPhoto("camera-photo-show")
            setMobilePhoto("camera-photo-hide")
        } else {
            setDesktopPhoto("camera-photo-hide")
            setMobilePhoto("camera-photo-show")
        }
      });
   

    const currentArray = () => {
        return arraySelect == 1 ? interestsPhotos1 : interestsPhotos2
    }

    const incrementIndex = () => {
        if(arraySelect == 1) {
            setArraySelect(2)
        } else {
            setArraySelect(1)
        }
    }
  
  return (
    <div id="about">
        <section id='about-main'>
            <img src={anyssaFrame} alt='picture of anyssa'/>
            <h1 id='main-header-about'>colorado wedding photographer and portraits</h1>
            <div id='about-main-text'>
                <h2>hey, friend!</h2>
                <p>More than anything, I want my clients to feel loved! I mean it when I say, I pray over each couple and want to make sure you leave our time together uplifted and encouraged.</p>
                <p>I'm always down to travel and hike for a good view, document a recreation of your first date, or find the perfect sunset spot for wedding photos! I love collaborating and making our vision come to life together.</p>
                <p>i love</p>
                <IconContext.Provider
                    value={{color: '#afb0b4'}} >
                    <AiOutlineDown />
                </IconContext.Provider>
            </div>
        </section>
        <section id='about-interests-section'>
            {currentArray().map(image => (
            <div className='polaroid-photo-border-about'>
                <img className="polaroid-photo-about" src={image.image} ref={ref} alt={image.alt} style={{ height: `${height}px` }}/>
                <p>{image.subtext}</p>
            </div>
            ))} 
            <span id='up-and-down'>
                <IconContext.Provider
                    value={{color: '#afb0b4', className: "up-and-down-font" }} >
                    <AiOutlineUp onClick={() => incrementIndex()}/>
                </IconContext.Provider>
                <IconContext.Provider
                    value={{color: '#afb0b4', className: "up-and-down-font" }} >
                    <AiOutlineDown onClick={() => incrementIndex()}/>
                </IconContext.Provider>
            </span>
        </section>
        <section>
            <div id='philosophy-section'>
                <div>
                    <h2>your personal storyteller</h2>
                    <p>the art of visual storytelling</p>
                    <p>is where i find my passions collide...</p>
                    <img src={anyssaCamera} alt='photographer' className={mobilePhoto}/>
                    <p>Not only will I document your story with authenticity and artistry, but I will also support and encourage you in the specific season of life that you're in! Whether you're planning a mountaintop wedding or celebrating your anniversary with your best friend, I'm so excited to cheer you on and capture those special moments with you.  </p>
                    <p>what to expect...</p>
                    <p>Once I receive your inquiry, we'll schedule a call to go over a few details and make sure we're the perfect fit! I'll walk you through every step leading up to your session or wedding and consider it a true joy to serve you!</p>
                    <p>Ready to book?</p>
                </div>
                <img src={anyssaCamera} alt='photographer' className={desktopPhoto} />
            </div>
            <div id='about-nav-buttons'>
                <div>
                    <IconContext.Provider
                        value={{color: '#afb0b4', className: 'down-arrow-nav'}} >
                        <AiOutlineDown />
                    </IconContext.Provider>
                    <Link to="/inquire" reloadDocument><button>book now</button></Link>
                </div>
                <div>
                    <IconContext.Provider
                        value={{color: '#afb0b4', className: 'down-arrow-nav'}}  >
                        <AiOutlineDown />
                    </IconContext.Provider>
                    <Link to="/portfolio"><button>galleries</button></Link>
                </div>
                <div>
                    <IconContext.Provider
                        value={{color: '#afb0b4', className: 'down-arrow-nav'}} >
                        <AiOutlineDown />
                    </IconContext.Provider>
                    <Link to="/investment"><button>details</button></Link>
                </div>
            </div>

        </section>
       
        <Footer />
    </div>
    
  )
}

export default AboutContainer

