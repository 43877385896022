import React from 'react'
import { motion } from 'framer-motion'
import anyssa from '../../Data/Inquire/Untitled design (5).jpg'
import Footer from '../HomeComponents/Footer'
import {Helmet} from "react-helmet";

function Inquire() {

  
 
  return (
    <motion.div initial={{opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div id='inquire-main'>
      <Helmet>
      <meta property='og:title' content='Inquire - Anyssa Sands Photography' />
        <meta property="og:url" content="https://anyssasands.com/inquire" />
      </Helmet>
        <section id='inquire-main-section' >
          <img src={anyssa} alt='photography' />
          <div>
            <h1>INQUIRE</h1>
            <h2>let's do this thing!</h2>
            <p>Please fill out the contact form below with details about your wedding or session :) I'll get back to you within 48 business hours, and seriously, am SO excited to hear from you!</p>
            <p>-Anyssa</p>
            <p>All other inquiries, please e-mail photography@anyssasands.com</p>
          </div>
        </section>
        <div className='inquire-form'>
          <div className="hb-p-61dc8e6dfcdcc30008339b1f-1"></div><img height="1" width="1" style={{display: "none"}} src="https://www.honeybook.com/p.png?pid=61dc8e6dfcdcc30008339b1f"></img>
        </div>
        <Footer />
      </div>
    </motion.div>
  )
}

export default Inquire