function importAllWeddings(r) {
    let images = {};
     r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images
   }

function importAllCouples(r) {
     r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images
   }

   const images = importAllWeddings(require.context('../../Data/Portfolio/Weddings', false, /\.(png|jpe?g|svg)$/))
   const images1 = importAllCouples(require.context('../../Data/Portfolio/Couples', false, /\.(png|jpe?g|svg)$/))
   const images2 = importAllCouples(require.context('../../Data/Portfolio/Weddings/Small', false, /\.(png|jpe?g|svg)$/))
   const images3 = importAllCouples(require.context('../../Data/Portfolio/Couples/Small', false, /\.(png|jpe?g|svg)$/))

    export const portfolioImages1 = [
        {
            id: 1,
            image: images['taccoa-falls-bride-detail-shot-necklace.jpg'],
            imageSmall: images['taccoa-falls-bride-detail-shot-necklace-sm.jpg'],
            alt: "taccoa falls bride necklace",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1381,
            divID: null
        },
        {
            id: 3,
            image: images['rifle-colorado-wedding-bride-and-groom-sunset-portrait.jpg'],
            imageSmall: images['rifle-colorado-wedding-bride-and-groom-sunset-portrait-sm.jpg'],
            alt: "rifle colorado bride sunset",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1456,
            divID: null
        },
        {
            id: 5,
            image: images['taccoa-falls-wedding-ring-detail-shot.jpg'],
            imageSmall: images['taccoa-falls-wedding-ring-detail-shot-sm.jpg'],
            alt: "toccoa falls ring detail shot",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1531,
            divID: null
        },
        {
            id: 7,
            image: images['taccoa-falls-bride-and-groom-gaze-wedding.jpg'],
            imageSmall: images['taccoa-falls-bride-and-groom-gaze-wedding-sm.jpg'],
            alt: "taccoa falls bride and groom",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1602,
            divID: null
        },
        {
            id: 9,
            image: images['bouquet-wedding-flowers-telluride-mountains-colorado.jpg'],
            imageSmall: images['bouquet-wedding-flowers-telluride-mountains-colorado-sm.jpg'],
            alt: "bouquet telluride wedding",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1500,
            divID: null
        },
        {
            id: 11,
            image: images['bride-mother-dress-detail-shot-taccoa-falls.jpg'],
            imageSmall: images['bride-mother-dress-detail-shot-taccoa-falls-sm.jpg'],
            alt: "mother and bride wedding",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1445,
            divID: null
        },
        {
            id: 13,
            image: images['telluride-bride-and-groom-portrait-shot-fall.jpg'],
            imageSmall: images['telluride-bride-and-groom-portrait-shot-fall-sm.jpg'],
            alt: "telluride colorado wedding mountain bride and groom",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1455,
            divID: null
        },
        {
            id: 15,
            image: images['bride-telluride-bouquet-profile.jpg'],
            imageSmall: images['bride-telluride-bouquet-profile-sm.jpg'],
            alt: "telluride bouquet wedding flowers",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1500,
            divID: null
        },
        {
            id: 17,
            image: images['bride-and-groom-breckenridge-mountains-kiss.jpg'],
            imageSmall: images['bride-and-groom-breckenridge-mountains-kiss-sm.jpg'],
            alt: "breckenridge colorado bride and groom",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1500,
            divID: null
        },
        {
            id: 19,
            image: images['breckenridge-groom-detail-shoes-wedding.jpg'],
            imageSmall: images['breckenridge-groom-detail-shoes-wedding-sm.jpg'],
            alt: "breckenridge groom detail shoes",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1457,
            divID: null
        }
    ]

    export const portfolioImages2 = [
        {
            id: 2,
            image: images['taccoa-falls-bride-waterfall.jpg'],
            imageSmall: images['taccoa-falls-bride-waterfall-sm.jpg'],
            alt: "waterfall bride dress",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1500,
            divID: null
        },
        {
            id: 4,
            image: images['taccoa-falls-detail-shot-flowers-bride.jpg'],
            imageSmall: images['taccoa-falls-detail-shot-flowers-bride-sm.jpg'],
            alt: "wedding flowers bride",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1500,
            divID: null
        },
        {
            id: 6,
            image: images['bride-and-groom-breckenridge-sunset-wedding.jpg'],
            imageSmall: images['bride-and-groom-breckenridge-sunset-wedding-sm.jpg'],
            alt: "bride and groom breckenridge",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1753,
            divID: null
        },
        {
            id: 8,
            image: images['bride-and-groom-wedding-telluride-mountain-shot.jpg'],
            imageSmall: images['bride-and-groom-wedding-telluride-mountain-shot-sm.jpg'],
            alt: "bride and groom telluride",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1442,
            divID: null
        },
        {
            id: 10,
            image: images['breckenridge-bride-getting-ready-bridal-suite.jpg'],
            imageSmall: images['breckenridge-bride-getting-ready-bridal-suite-sm.jpg'],
            alt: "breckenridge bridal suite shoes",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1557,
            divID: null
        },
        {
            id: 12,
            image: images['telluride-wedding-ceremony-kiss-sunset.jpg'],
            imageSmall: images['telluride-wedding-ceremony-kiss-sunset-sm.jpg'],
            alt: "wedding ceremony telluride",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1500,
            divID: null
        },
        {
            id: 14,
            image: images['bride-breckenridge-mountain-scape-bouquet-wedding.jpg'],
            imageSmall: images['bride-breckenridge-mountain-scape-bouquet-wedding-sm.jpg'],
            alt: "bride bouquet breckenridge colorado",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1525,
            divID: null
        },
        {
            id: 16,
            image: images['bride-shoes-detail-shot-bridal-suite-breckenridge.jpg'],
            imageSmall: images['bride-shoes-detail-shot-bridal-suite-breckenridge-sm.jpg'],
            alt: "breckenridge bride detail shoes",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1510,
            divID: null
        },
        {
            id: 18,
            image: images['waterfall-bride-and-groom-bouquet.jpg'],
            imageSmall: images['waterfall-bride-and-groom-bouquet-sm.jpg'],
            alt: "waterfall bride and groom",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1571,
            divID: null
        },
        {
            id: 20,
            image: images['rifle-colorado-wedding-detail-shot-rings.jpg'],
            imageSmall: images['rifle-colorado-wedding-detail-shot-rings-sm.jpg'],
            alt: "wedding rings detail shot",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1496,
            divID: null
        }
    ]

    export const carousel = [
        {
            id: 0,
            image: images['telluride-bride-and-groom-elopment.jpg'],
            alt: "telluride bride and groom",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1496,
            divID: null
        },
        {
            id: 1,
            image: images['taccoa-falls-bride-detail-shot-necklace.jpg'],
            alt: "taccoa falls bride necklace",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 2,
            image: images['taccoa-falls-bride-waterfall.jpg'],
            alt: "waterfall bride dress",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 3,
            image: images['rifle-colorado-wedding-bride-and-groom-sunset-portrait.jpg'],
            alt: "rifle colorado bride sunset",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 4,
            image: images['taccoa-falls-detail-shot-flowers-bride.jpg'],
            alt: "wedding flowers bride",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 5,
            image: images['taccoa-falls-wedding-ring-detail-shot.jpg'],
            alt: "toccoa falls ring detail shot",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 6,
            image: images['bride-and-groom-breckenridge-sunset-wedding.jpg'],
            alt: "bride and groom breckenridge",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 7,
            image: images['taccoa-falls-bride-and-groom-gaze-wedding.jpg'],
            alt: "taccoa falls bride and groom",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 8,
            image: images['bride-and-groom-wedding-telluride-mountain-shot.jpg'],
            alt: "bride and groom telluride",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 9,
            image: images['bouquet-wedding-flowers-telluride-mountains-colorado.jpg'],
            alt: "bouquet telluride wedding",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 10,
            image: images['breckenridge-bride-getting-ready-bridal-suite.jpg'],
            alt: "breckenridge bridal suite shoes",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 11,
            image: images['bride-mother-dress-detail-shot-taccoa-falls.jpg'],
            alt: "mother and bride wedding",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 12,
            image: images['telluride-wedding-ceremony-kiss-sunset.jpg'],
            alt: "wedding ceremony telluride",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 13,
            image: images['telluride-bride-and-groom-portrait-shot-fall.jpg'],
            alt: "telluride colorado wedding mountain bride and groom",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 14,
            image: images['bride-breckenridge-mountain-scape-bouquet-wedding.jpg'],
            alt: "bride bouquet breckenridge colorado",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 15,
            image: images['bride-telluride-bouquet-profile.jpg'],
            alt: "telluride bouquet wedding flowers",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 16,
            image: images['bride-shoes-detail-shot-bridal-suite-breckenridge.jpg'],
            alt: "breckenridge bride detail shoes",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 17,
            image: images['bride-and-groom-breckenridge-mountains-kiss.jpg'],
            alt: "breckenridge colorado bride and groom",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 18,
            image: images['waterfall-bride-and-groom-bouquet.jpg'],
            alt: "waterfall bride and groom",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 19,
            image: images['breckenridge-groom-detail-shoes-wedding.jpg'],
            alt: "breckenridge groom detail shoes",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 20,
            image: images['rifle-colorado-wedding-detail-shot-rings.jpg'],
            alt: "wedding rings detail shot",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        }
    ]

    export const portfolioImages4 = [
        {
            id: 2,
            image: images['colorado-couple-outdoors-hugging.jpg'],
            imageSmall: images['colorado-couple-outdoors-hugging-sm.jpg'],
            alt: "cherry creek state park colorado couple",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1651,
            divID: null
        },
        {
            id: 4,
            image: images['couple-artsy-colorado-outdoors-stare.jpg'],
            imageSmall: images['couple-artsy-colorado-outdoors-stare-sm.jpg'],
            alt: "couple colorado nature",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1566,
            divID: null
        },
        {
            id: 6,
            image: images['denver-colorado-lake-couple-laugh.jpg'],
            imageSmall: images['denver-colorado-lake-couple-laugh-sm.jpg'],
            alt: "colorado couple artsy outdoors",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1300,
            height: 867,
            divID: null
        },
        {
            id: 8,
            image: images['artsy-colorado-couple.jpg'],
            imageSmall: images['artsy-colorado-couple-sm.jpg'],
            alt: "colorado couple artsy outdoors",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1647,
            divID: null
        }
    ]

    export const portfolioImages3 = [
        {
            id: 1,
            image: images['colorado-couple-profile-artsy.jpg'],
            imageSmall: images['colorado-couple-profile-artsy-sm.jpg'],
            alt: "colorado couple profile",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1496,
            divID: null
        },
        {
            id: 3,
            image: images['dancing-couple-lake-colorado.jpg'],
            imageSmall: images['dancing-couple-lake-colorado-sm.jpg'],
            alt: "sunset colorado couple",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1300,
            height: 882,
            divID: null
        },
        {
            id: 5,
            image: images['colorado-sunset-couple.jpg'],
            imageSmall: images['colorado-sunset-couple-sm.jpg'],
            alt: "sunset colorado couple lake",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1480,
            divID: null
        },
        {
            id: 7,
            image: images['laughing-colorado-couple-nature.jpg'],
            imageSmall: images['laughing-colorado-couple-nature-sm.jpg'],
            alt: "colorado couple laughing",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1000,
            height: 1500,
            divID: null
        },
        {
            id: 9,
            image: images['colorado-couple-hugging-married.jpg'],
            imageSmall: images['colorado-couple-hugging-married-sm.jpg'],
            alt: "colorado couple hugging",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            width: 1300,
            height: 867,
            divID: null
        }
    ]

    export const carousel2 = [
        {
            id: 0,
            image: images['denver-lake-dance-couple-colorado.jpg'],
            alt: "denver lake couple",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 1,
            image: images['colorado-couple-profile-artsy.jpg'],
            alt: "colorado couple profile",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 2,
            image: images['colorado-couple-outdoors-hugging.jpg'],
            alt: "cherry creek state park colorado couple",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 3,
            image: images['dancing-couple-lake-colorado.jpg'],
            alt: "sunset colorado couple",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 4,
            image: images['couple-artsy-colorado-outdoors-stare.jpg'],
            alt: "couple colorado nature",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 5,
            image: images['colorado-sunset-couple.jpg'],
            alt: "sunset colorado couple lake",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 6,
            image: images['denver-colorado-lake-couple-laugh.jpg'],
            alt: "colorado couple artsy outdoors",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 7,
            image: images['laughing-colorado-couple-nature.jpg'],
            alt: "colorado couple laughing",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 8,
            image: images['artsy-colorado-couple.jpg'],
            alt: "colorado couple artsy outdoors",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        },
        {
            id: 9,
            image: images['colorado-couple-hugging-married.jpg'],
            alt: "colorado couple hugging",
            imageClass: "wedding-photos",
            divClass: "portfolio-container",
            divID: null
        }
    ]