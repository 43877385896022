import React from 'react'
import HomePhotoContainer from './HomePhotoContainer'
import { motion } from 'framer-motion'
import {Helmet} from "react-helmet";

function Home({ render }) {


  return (
    <div>
      <Helmet>
      <meta property='og:title' content='Anyssa Sands Photography' />
        <meta property="og:url" content="https://anyssasands.com/" />
      </Helmet>
      <motion.div initial={{opacity: 0 }} transition={{ duration: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <HomePhotoContainer render={ render }/>
      </motion.div>
    </div>
  )
}

export default Home