import React, { useRef, useLayoutEffect, useState } from 'react'
import Footer from '../HomeComponents/Footer'
import { motion } from 'framer-motion'
import weddings from '../../Data/Portfolio/FrontPage/colorado wedding bride groom palmer lake.jpg'
import engagements from '../../Data/Portfolio/FrontPage/couple colorado sunset lake.jpg'
import { Link } from "react-router-dom"
import {Helmet} from "react-helmet";

function Portfolio() {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useLayoutEffect(() => {
    window.addEventListener('resize', function() {
      if (ref.current) {
        setHeight((ref.current.clientWidth * 1.4));
      }
    });
    if (ref.current) {
      setHeight((ref.current.clientWidth * 1.4));
    }
  }, [ref]);
    
  return (
    <motion.div initial={{opacity: 0 }} transition={{ duration: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div>
        <Helmet>
        <meta property='og:title' content='Portfolio - Anyssa Sands Photography' />
          <meta property="og:url" content="https://anyssasands.com/inquire" />
        </Helmet>
        <div id='portfolio-main'>
          <div id='portfolio-border'>
            <h1>browse my galleries!</h1>
            <section id='polaroid-section-portfolio'>
              <div className='polaroid-photo-border-portfolio'>
                <Link to="/portfolio/weddings"><img src={weddings} ref={ref} style={{ height: `${height}px` }} className="polaroid-photo-portfolio" /></Link>
                <p>weddings</p>
              </div>
              <div className='polaroid-photo-border-portfolio'>
                <Link to="/portfolio/couples"><img src={engagements} style={{ height: `${height}px` }} className="polaroid-photo-portfolio" /></Link>
                <p>couples</p>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </motion.div>
  )
}

export default Portfolio