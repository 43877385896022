import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Components/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom"
const form = () => {
  return <div>
  <div className="hb-p-61dc8e6dfcdcc30008339b1f-1"></div><img height="1" width="1" style={{display: "none"}} src="https://www.honeybook.com/p.png?pid=61dc8e6dfcdcc30008339b1f"></img>
</div>
}
ReactDOM.render(
  
  <React.StrictMode>
    <BrowserRouter>
      <App form={form}/>
      
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
