import React, { useState } from 'react'
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs'
import { IconContext } from 'react-icons'
import Footer from '../HomeComponents/Footer'
import { portfolioImages1, portfolioImages2, carousel } from './Data'
import tellurideWedding from '../../Data/Portfolio/Weddings/telluride-bride-and-groom-elopment.jpg'
import tellurideWeddingSmall from '../../Data/Portfolio/Weddings/Small/telluride-bride-and-groom-elopment-sm.jpg'
import { motion } from 'framer-motion'

function WeddingPhotos() {
    
    const [modalPosition, setModalPosition] = useState(0)
    const [isShowModal, setIsShowModal] = useState(false)

    const setModalIndex = (id) => {
        setModalPosition(id)
        setIsShowModal(!isShowModal)
    }

    const closeModal = () => {
        setIsShowModal(false)
    }

    const increasePosition = () => {
        if(modalPosition == carousel.length - 1) {
            setModalPosition(0)
        } else {
            setModalPosition(modalPosition + 1)
        }
        
    }

    const decreasePosition = () => {
        if(modalPosition == 0) {
            setModalPosition(20)
        } else {
            setModalPosition(modalPosition - 1)
        }
    }

  return (
    <motion.div initial={{opacity: 0 }} transition={{ duration: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div>
            <section id='main-wedding-photos'>
                <div id='wedding-border'>
                <div id='top-photo-container'>
                    <img 
                    onClick={() => setModalIndex(0)} 
                    src={tellurideWeddingSmall} 
                    srcSet={`${tellurideWeddingSmall} 800w, ${tellurideWedding} 2000w`}
                    sizes="(min-width: 700px) 1000w, 400w"
                    alt="telluride wedding mountains kissing" 
                    className='wedding-photo-top' 
                    width="1300" 
                    height="867" 
                    oncontextmenu="return false" 
                    />
                </div>
                {/* <img src={portfolioImages1[1].imageSmall} /> */}
                    <div id='wedding-inner-border'>
                        <div id='wedding-column-1'>
                            {portfolioImages1.map(photo => (
                                <img 
                                onClick={() => setModalIndex(photo.id)} 
                                alt={photo.alt} 
                                className={photo.imageClass} 
                                width={photo.width} 
                                height={photo.height} 
                                srcSet={`${photo.imageSmall} 800w, ${photo.image} 2000w`}
                                sizes="(min-width: 700px) 1000w, 400w"
                                src={photo.imageSmall}
                                oncontextmenu="return false"  
                                />
                            ))}
                        </div>
                        <div id='wedding-column-2'>
                            {portfolioImages2.map(photo => (
                                <img 
                                onClick={() => setModalIndex(photo.id)} 
                                src={photo.imageSmall} 
                                alt={photo.alt} 
                                className={photo.imageClass} 
                                width={photo.width} 
                                height={photo.height}
                                srcSet={`${photo.imageSmall} 800w, ${photo.image} 2000w`}
                                sizes="(min-width: 700px) 1000w, 400w"
                                oncontextmenu="return false" 
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <div id="myModal" class={isShowModal ? "modal-show" : "modal-hide"}>
                <span className="close" onClick={() => closeModal()}>&times;</span>
                <IconContext.Provider
                    value={{color: '#afb0b4', className: 'wedding-nav-right'}} >
                    <BsChevronRight onClick={() => increasePosition()} />
                </IconContext.Provider>
                <img class="modal-content" id="img01" src={carousel[modalPosition].image}/>
                <IconContext.Provider
                    value={{color: '#afb0b4', className: 'wedding-nav-left'}} >
                    <BsChevronLeft onClick={() => decreasePosition()} />
                </IconContext.Provider>
                <div id="caption"></div>
            </div>
            <Footer />
        </div>
    </motion.div>
  )
}

export default WeddingPhotos