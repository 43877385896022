import React, {useEffect, useState, useRef} from 'react'
import { Link } from "react-router-dom"
import Footer from './Footer'
import audreyWedding from '../../Data/Home/Audrey Taccoa Falls wedding.jpg'
import flowers from '../../Data/Home/taccoa falls detail shot flowers bride.jpg'
import megan from '../../Data/Home/Megan & Jordan Sample Gallery-39.jpg'
import audreyWeddingPort from '../../Data/Home/bride dress back porch toccoa falls.jpg'
import shoes from '../../Data/Home/Shoes-1.jpg'
import camera from '../../Data/Home/Audrey & Matt-24.jpg'
import nyss from '../../Data/Home/Nyss.jpg'




function HomePhotoContainer({ render }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const count = useRef(0)
  const [isChange, setIsChange] = useState(true)
  const newHomePhotos = [
      {
        id: 1,
        image: audreyWedding,
        alt: "bride waterfall",
        width: 1500,
        height: 2250
      },
      {
        id: 2,
        image: flowers,
        alt: "wedding bouquet",
        width: 1500,
        height: 2250
      },
      {
        id: 3,
        image: megan,
        alt: "bride and groom colorado",
        width: 1500,
        height: 2259
      }
    ]
  const homeFooterPhotos = [
      {
        id: 1,
        image: audreyWeddingPort,
        alt: "toccoa falls wedding bride groom",
        subtext: "portfolio",
        path: "/portfolio"
      },
      {
        id: 2,
        image: shoes,
        alt: "wedding shoes",
        subtext: "investment",
        path: "/investment"
      }
    ]

    useEffect(() => {
      if (isChange === true) {
      const intervalId = setInterval(() => {
        count.current = count.current + 1
        if(count.current === 3) {
            setCurrentIndex(0);
            count.current = 0
        } 
        else {
            setCurrentIndex(currentIndex => currentIndex + 1);
        }
    }, 5000)

    return () => clearInterval(intervalId);

      } else {
        setCurrentIndex(currentIndex)
      }
    }, [isChange])

   

  return (
      <>
    <section>
      <section id="pics">
        {newHomePhotos.map(image => (
          <img src={image.image} alt={image.alt} width={image.width} height={image.height}/>
        ))}
      </section>
    </section>
    <section id='home-about-section'>
      <h1 className='main-font'>colorado wedding &amp; portrait photographer</h1>
      <p id='about-head-subtext' className='accent-font'>adventurous - artistic - authentic</p>
      <div id='home-about-split'>
        <div >
          <p className='main-font home-about-text'>Hey there! I'm Anyssa, your Colorado Photographer! Here to capture your genuine love and be a friend on the journey. I love photographing outdoor adventures (take me to your favorite mountaintop!), romance, laughter, and all the emotions that come with celebrating your wedding day! I'm so excited you're here and can't wait to meet you. :)</p>
          <Link to="/about" className='accent-font more-about-me'>more about me &gt;</Link>
        </div>
        <div>
          <img id='nyss-home-about' src={nyss} alt='photo of anyssa' width="1500" height="2250"/>
          <Link to='/inquire' className='accent-font book-now' reloadDocument>BOOK NOW</Link>
        </div>
      </div>
    </section>
    <section id='quote-super-section'>
      <div id='quote-section'>
        <p>"take care of all your memories, for you cannot relive them."</p>
        <p>-bob dylan</p>
      </div>
    </section>
    <section rel="preload">
        <div  className="back-img"></div>
    </section>
    <section id='polaroid-section'>
          {homeFooterPhotos.map(image => (
            <div className='polaroid-photo-border'>
              <Link to={image.path}><img className="polaroid-photo" src={image.image} alt={image.alt}/></Link>
              <Link to={image.path}>
                <p>{image.subtext}</p>
              </Link>
            </div>
          ))}
          <div className='polaroid-photo-border'>
            <Link to='/inquire' reloadDocument><img className="polaroid-photo" src={camera} alt="wedding shoes" /></Link>
            <Link to="/inquire" reloadDocument>
              <p>inquire</p>
            </Link>
          </div>
    </section>
    <section id='rotating-review-section'>
        {currentIndex == 0 ? <p id='thomas'>"Anyssa was absolutely incredible to work with!" -Thomas &amp; Savanna</p> : null }
        {currentIndex == 1 ? <p id='lori'>"We are in awe of how beautifully your work framed every gorgeous and emotional detail of our vow renewal in an unforgettable way..." -Audrey &amp; Matt</p> : null }
        {currentIndex == 2 ? <p id='emilee'>"The whole process was so easy and efficient. We feel like we can be awkward doing photos but it didn't feel awkward AT ALL. We loved our experience..." -Rachel &amp; Travis</p> : null}
    </section>
    <div>
      <Footer />
    </div>
    
    </>
  )
}

export default HomePhotoContainer