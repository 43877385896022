import React, { useRef, useLayoutEffect, useState } from 'react'
import mountainBride from '../../Data/Investment/1 Lindsey on mountain.jpg'
import hikingBoots from '../../Data/Investment/detail bride and groom dress colorado.jpg'
import audrey from '../../Data/Investment/couples mt falcon.jpg'
import Footer from '../HomeComponents/Footer'
import { motion } from 'framer-motion'
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom"

function Investment() {
  const [polaroidHeight, setPolaroidHeight] = useState(0)
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const refPolaroid = useRef(null);

  useLayoutEffect(() => {
    window.addEventListener('resize', function() {
      if (ref.current) {
        setHeight((ref.current.clientWidth * .4));
      }
    });
    if (ref.current) {
      setHeight((ref.current.clientWidth * .4));
    }
  }, [ref]);

  useLayoutEffect(() => {
    window.addEventListener('resize', function() {
      if (refPolaroid.current) {
        setPolaroidHeight((refPolaroid.current.clientWidth * 1.45));
      }
    });
    if (refPolaroid.current) {
      setPolaroidHeight((refPolaroid.current.clientWidth * 1.45));
    }
  }, [refPolaroid]);

  return (
    <motion.div initial={{opacity: 0 }} transition={{ duration: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
    <div id='investment-main'>
      <Helmet>
        <meta property='og:title' content='Investment - Anyssa Sands Photography' />
        <meta property="og:url" content="https://anyssasands.com/inquire" />
      </Helmet>
      <h1 id='main-header-investment'>Colorado wedding photographer investment portraits</h1>
      <section id='invest-in-the-moments'>
        <img src={mountainBride} id='investment-main-photo' alt='bride on mountain' ref={ref} style={{ height: `${height}px` }}/>
        <p>invest in the moments</p>
        <p>i want you to enjoy the moment... i'll take care of the rest.</p>
        <p>When you book with me, my goal is to help you relax, let loose, and just enjoy the moment with the love of your life! I also want you to leave feeling known, appreciated, and deeply loved. My heart is to connect with you, hear your story, celebrate you, and ultimately cheer you on in whatever adventure you're on!</p>
      </section>
      <div id='investment-review-strip'>
        <p>"You truly have such a gifted eye and we are shocked at how good we look... THANK YOU THANK YOU THANK YOU."</p>
        <p>-Audrey &amp; Matt</p>
      </div>
     <section id='invest-memories'>
      <h1>preserve your memories</h1>
      <hr/>
      <p id='pricing'>PRICING</p>
      <div id='pricing-polaroid-section'>
        <div className='polaroid-photo-border-investment' >
          <Link to="/inquire" reloadDocument><img src={audrey} alt='engaged couple' ref={refPolaroid} style={{ height: `${polaroidHeight}px` }} className="polaroid-photo-investment" /></Link>
          <Link to="/inquire" reloadDocument>
            <p>couples</p>
          </Link>
          <p>STARTS AT $250</p>
        </div>
        <div className='polaroid-photo-border-investment' >
          <Link to="/inquire" reloadDocument><img src={hikingBoots} alt='bride in hiking boots' style={{ height: `${polaroidHeight}px` }} className="polaroid-photo-investment" /></Link>
          <Link to="/inquire" reloadDocument>
            <p>weddings</p>
          </Link>
          <p>STARTS AT $2,320</p>
        </div>
      </div>
     </section>
     <div id='investment-inquire-section'>
      <p id='ivestment-inquire-main-text'>To find out more, please submit an inquiry form to receive my detailed pricing guide!</p>
      <Link to="/inquire" reloadDocument><button id='inquire-button'>INQUIRE</button></Link>
      <Link to="/portfolio">
        <p id='browse-galleries'>&gt;&gt;&gt; BROWSE MY GALLERIES HERE</p>
      </Link>
     </div>
        <Footer />
    </div>
    </motion.div>
  )
}

export default Investment