import React, { useState, useLayoutEffect, useRef } from 'react'
import barn from '../Data/Instagram/wedding-barn-venue-colorado.jpg'
import ring from '../Data/Instagram/bride-and-groom-colorado-palmer-lake.jpg'
import veil from '../Data/Instagram/bride-and-groom-veil.jpg'
import ringFlower from '../Data/Instagram/Ring-Shots-Flower-Macro-3.jpg'
import dress from '../Data/Instagram/wedding-dress.jpg'
import barnSmall from '../Data/Instagram/Small/wedding-barn-venue-colorado-sm.jpg'
import ringSmall from '../Data/Instagram/Small/bride-and-groom-colorado-palmer-lake-sm.jpg'
import veilSmall from '../Data/Instagram/Small/bride-and-groom-veil-sm.jpg'
import ringFlowerSmall from '../Data/Instagram/Small/Ring-Shots-Flower-Macro-sm.jpg'
import dressSmall from '../Data/Instagram/Small/wedding-dress-sm.jpg'

function Instagram() {
  const [height, setHeight] = useState(0);
  const [oddHeight, setOddHeight] = useState(0)
  const ref = useRef(null);
    const homePhotosArry =[
        {
          id: 1,
          image: ring,
          imageSmall: ringSmall,
          alt: "bride and groom detail ring"
        },
        {
          id: 2,
          image: barn,
          imageSmall: barnSmall,
          alt: "wedding venue barn"
        },
        {
          id: 3,
          image: veil,
          imageSmall: veilSmall,
          alt: "bride and groom under veil"
        },
        {
          id: 4,
          image: ringFlower,
          imageSmall: ringFlower,
          alt: "engagement ring flowers"
        },
        {
          id: 5,
          image: dress,
          imageSmall: dressSmall,
          alt: "wedding dress"
        }
    ]

    useLayoutEffect(() => {
      window.addEventListener('resize', function() {
        if (ref.current) {
          setHeight((ref.current.clientWidth * 1));
          setOddHeight((ref.current.clientWidth *1.1))
        }
      });
      if (ref.current) {
        setHeight((ref.current.clientWidth * 1));
        setOddHeight((ref.current.clientWidth *1.1))
      }
    }, [ref]);
  

    
    // const instaKey = process.env.REACT_APP_INSTAGRAM_TOKEN

    // useEffect(() => {
    //     fetch(`https://graph.instagram.com/me/media?fields=id,media_url,caption&access_token=${instaKey}`)
    //     .then(res => res.json())
    //     .then(data => setInstagram(data.data.slice(0,6)))
    //     }, [])

  return (
    <section id='instagram'>
        <div>
            {/* <a href='https://www.instagram.com/anyssa.sands.photography/' target="_blank">
            <img id='insta-icon' src='https://lh3.googleusercontent.com/pw/AM-JKLXZ9hkdutRd0MF-BvNqaITa5QorTZuGSyUtaILIJIReqEKiwQS9rRKP9VofSvUHe1ULobP74_wjvFTZ_WmfH2-pOebUeiTh2yDG7b_8Wt5pqDdF2OcgfDSgmMvNydytL8Pvhkiv1Q3C5RqZZI5IX1zg=s96-no?authuser=0' alt='icon' />
            </a> */}
            {/* <p id='follow-me'>instagram: <a href='https://www.instagram.com/anyssa.sands.photography/' target="_blank">@anyssa.sands.photography</a> </p> */}
            <p id='follow-along'>FOLLOW ALONG <a href='https://www.instagram.com/anyssa.sands.photography/' target="_blank">@ANYSSA.SANDS.PHOTOGRAPHY</a></p>
            
        </div>
        <div id='insta-photo-container'>
            {homePhotosArry.map(photo => (
                <img 
                key={photo.id} 
                ref={ref} 
                style={photo.id % 2 == 0 ? { height: `${height}px` } : { height: `${oddHeight}px` }} 
                className={photo.id % 2 == 0 ? "even-insta insta-photos" : "odd-insta insta-photos"} 
                src={photo.imageSmall} 
                alt={photo.id}
                srcSet={`${photo.imageSmall} 800w, ${photo.image} 2000w`}
                sizes="(min-width: 700px) 1000w, 100w"
                />
            ))}
        </div>
    </section>
  )
}

export default Instagram