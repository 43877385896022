
import '../style.css';
import React, {useEffect} from 'react'
import { Routes, Route } from "react-router-dom"
import Header from './Header';
import Home from './HomeComponents/Home';
import About from './AboutComponents/About';
import Portfolio from './Portfolio.js/Portfolio';
import Investment from './Investment/Investment';
import Inquire from './Inquire/Inquire';
import WeddingPhotos from './Portfolio.js/WeddingPhotos';
import CouplesPhotos from './Portfolio.js/CouplesPhotos';
import { AnimatePresence } from 'framer-motion'
import ScrollToTop from '../ScrollToTop';

function App() {
 
  return (
    <div className="App">
      
      <Header />
      <AnimatePresence >
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/investment" element={<Investment />} />
          <Route exact path="/inquire" element={<Inquire />} />
          <Route exact path="/portfolio/weddings" element={<WeddingPhotos />} />
          <Route exact path="/portfolio/couples" element={<CouplesPhotos />} />
      </Routes>
     </AnimatePresence>
     
    </div>
  );
}

export default App;
