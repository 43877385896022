import React, { useState } from 'react'
import { Link, NavLink, useLocation } from "react-router-dom"
import { VscMenu } from 'react-icons/vsc'
import { CgClose } from 'react-icons/cg'
import logo from '../Data/Home/AS Logo (The Seasons, and IM Fell English SC)-cropped.svg'


function Header() {
    const [isChecked, setIsChecked] = useState(false)

    const handleChecked = () => {
        setIsChecked(!isChecked)
    }

    const handleCheckedFalse = () => {
        setIsChecked(false)
    }

  return (
    <header>
        <div id='header-div'>
            <Link to="/" className='header-logo-link'><img src={logo} alt="logo" id="logo" width="400" height="57" /></Link>
            <nav>
                <input type="checkbox" id="check" checked={isChecked} onChange={handleChecked} />
                <label for="check">
                    <VscMenu id="button"/>
                    <CgClose id='cancel' />
                </label>
                <ul>
                    <li onClick={handleCheckedFalse}><NavLink to="/"  className={({ isActive }) => (isActive ? 'is-active' : 'inactive')} >Home</NavLink></li>
                    <li onClick={handleCheckedFalse}><NavLink to="/about" className={({ isActive }) => (isActive ? 'is-active' : 'inactive')} >About</NavLink></li>
                    <li onClick={handleCheckedFalse}><NavLink to="/portfolio" className={({ isActive }) => (isActive ? 'is-active' : 'inactive')} >Portfolio</NavLink></li>
                    <li onClick={handleCheckedFalse}><NavLink to="/investment" className={({ isActive }) => (isActive ? 'is-active' : 'inactive')} >Investment</NavLink></li>
                    <li onClick={handleCheckedFalse}><NavLink to="/inquire" reloadDocument className={({ isActive }) => (isActive ? 'is-active' : 'inactive')} >Inquire</NavLink></li>
                </ul>
            </nav>
            
        </div>
    </header>
  )
}

export default Header