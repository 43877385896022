import React from 'react'
import Instagram from '../Instagram'
import { RiFacebookFill } from 'react-icons/ri'
import { AiFillInstagram } from 'react-icons/ai'

function Footer() {
  return (
    <footer>
      <Instagram />
      <div id='icons'>
      <a href='https://www.facebook.com/anyssa.sands.photography/' target="_blank"><RiFacebookFill /></a>
        <a href='https://www.instagram.com/anyssa.sands.photography/' target="_blank"><AiFillInstagram /></a>
      </div>
      <a className='built' href="https://github.com/DavidMSands">Website Built by David Sands</a>
   </footer>
  )
}

export default Footer